import img from "../../assets/images/certificate/ISO_9001-2015.png";

const displayData = [
  { id: 1, name: "Modular Wiring Solutions", status: "active" },
  { id: 2, name: "PLC/DCS Field Interface Module", status: "active" },
  { id: 3, name: "Signal Conditioning and Isolator", status: "inactive" },
  { id: 4, name: "Intrinsic Safety Isolator", status: "active" },
  { id: 5, name: "Panel Accessories", status: "inactive" },
  { id: 6, name: "Fiber Optic Patch Panels", status: "active" },
  { id: 7, name: "Prefabricated Cable", status: "active" },
  { id: 8, name: "Customized Solutions", status: "inactive" },
  { id: 9, name: "Industrial Ethernet", status: "active" },
  { id: 10, name: "Profibus and Profinet", status: "inactive" },
  { id: 11, name: "Protocol Gateway and Converter", status: "active" },
  { id: 12, name: "Industrial Connectivity", status: "inactive" },
  { id: 13, name: "Control and Supervisory System", status: "active" },
  { id: 14, name: "Field Instruments", status: "inactive" },
  { id: 15, name: "Process Instruments", status: "active" },
  { id: 16, name: "HART Interfacing", status: "inactive" },
  { id: 17, name: "Foundation Fieldbus", status: "active" },
  { id: 18, name: "HMI and Visualisation", status: "inactive" },
  { id: 19, name: "Surge Protection", status: "active" },
  { id: 20, name: "Process Alarm", status: "inactive" },
  { id: 21, name: "Gas Analysis", status: "active" },
  { id: 22, name: "Gas Detection System", status: "inactive" },
  { id: 23, name: "Fire Alarm System", status: "active" },
  { id: 24, name: "Alcohol and Drug Testing", status: "inactive" },
  { id: 25, name: "Respiratory Protection", status: "active" },
  { id: 26, name: "Power Supplies", status: "inactive" },
  { id: 27, name: "Remote I/O and Controller", status: "active" },
  { id: 28, name: "Energy Metering", status: "inactive" },
  { id: 29, name: "Process Display", status: "active" },
];

const certificateData = [
  {
    id: 1,
    certificateName: "ISO 9001",
    status: "active",
    certificateImage: img,
  },
  {
    id: 2,
    certificateName: "ISO 14001",
    status: "inactive",
    certificateImage: img,
  },
  {
    id: 3,
    certificateName: "ISO 45001",
    status: "active",
    certificateImage: img,
  },
  {
    id: 4,
    certificateName: "OHSAS 18001",
    status: "inactive",
    certificateImage: img,
  },
  {
    id: 5,
    certificateName: "CE Marking",
    status: "active",
    certificateImage: img,
  },
  {
    id: 6,
    certificateName: "UL Certification",
    status: "inactive",
    certificateImage: img,
  },
  {
    id: 7,
    certificateName: "RoHS Compliance",
    status: "active",
    certificateImage: img,
  },
  {
    id: 8,
    certificateName: "FCC Certification",
    status: "inactive",
    certificateImage: img,
  },
  {
    id: 9,
    certificateName: "ISO/IEC 27001",
    status: "active",
    certificateImage: img,
  },
  {
    id: 10,
    certificateName: "ISO/IEC 20000",
    status: "inactive",
    certificateImage: img,
  },
];

const brandData = [
  {
    id: 1,
    brandName: "Honeywell",
    brandFullName: "Honeywell Automation India Ltd.",
    status: "active",
    brandLogo:
      "https://media.istockphoto.com/id/1372577388/photo/old-electronic-devices-on-a-dark-background-the-concept-of-recycling-and-disposal-of.jpg?s=612x612&w=0&k=20&c=RGm3eCA76Y_IJJYGCLWS9acSR39Gb7iqsC_DIhJyG2g=",
  },
  {
    id: 2,
    brandName: "Eaton-MTL",
    brandFullName: "Eaton-MTL",
    status: "inActive",
    brandLogo:
      "https://media.istockphoto.com/id/1372577388/photo/old-electronic-devices-on-a-dark-background-the-concept-of-recycling-and-disposal-of.jpg?s=612x612&w=0&k=20&c=RGm3eCA76Y_IJJYGCLWS9acSR39Gb7iqsC_DIhJyG2g=",
  },
  {
    id: 3,
    brandName: "UL",
    brandFullName: "UL Automation Solutions Pvt. Ltd.",
    status: "active",
    brandLogo:
      "https://media.istockphoto.com/id/1372577388/photo/old-electronic-devices-on-a-dark-background-the-concept-of-recycling-and-disposal-of.jpg?s=612x612&w=0&k=20&c=RGm3eCA76Y_IJJYGCLWS9acSR39Gb7iqsC_DIhJyG2g=",
  },
  {
    id: 4,
    brandName: "Draeger",
    brandFullName: "Draeger Safety India Pvt. Ltd.",
    status: "active",
    brandLogo:
      "https://media.istockphoto.com/id/1372577388/photo/old-electronic-devices-on-a-dark-background-the-concept-of-recycling-and-disposal-of.jpg?s=612x612&w=0&k=20&c=RGm3eCA76Y_IJJYGCLWS9acSR39Gb7iqsC_DIhJyG2g=",
  },
  {
    id: 5,
    brandName: "Helmholz",
    brandFullName: "Helmholz",
    status: "inActive",
    brandLogo:
      "https://media.istockphoto.com/id/1372577388/photo/old-electronic-devices-on-a-dark-background-the-concept-of-recycling-and-disposal-of.jpg?s=612x612&w=0&k=20&c=RGm3eCA76Y_IJJYGCLWS9acSR39Gb7iqsC_DIhJyG2g=",
  },
  {
    id: 6,
    brandName: "HMS",
    brandFullName: "HMS",
    status: "active",
    brandLogo:
      "https://media.istockphoto.com/id/1372577388/photo/old-electronic-devices-on-a-dark-background-the-concept-of-recycling-and-disposal-of.jpg?s=612x612&w=0&k=20&c=RGm3eCA76Y_IJJYGCLWS9acSR39Gb7iqsC_DIhJyG2g=",
  },
  {
    id: 7,
    brandName: "Weidmuller",
    brandFullName: "Weidmuller",
    status: "inActive",
    brandLogo:
      "https://media.istockphoto.com/id/1372577388/photo/old-electronic-devices-on-a-dark-background-the-concept-of-recycling-and-disposal-of.jpg?s=612x612&w=0&k=20&c=RGm3eCA76Y_IJJYGCLWS9acSR39Gb7iqsC_DIhJyG2g=",
  },
];

const categoryData = [
  { id: 1, categoryName: "Utility Module", status: "active" },
  { id: 2, categoryName: "Power Distribution Module", status: "InActive" },
  { id: 3, categoryName: "Diode Oring Module", status: "InActive" },
  { id: 4, categoryName: "Fan Failure Detection Module", status: "InActive" },
  { id: 5, categoryName: "Digital Thermostat", status: "active" },
  { id: 6, categoryName: "Temperature Monitoring Unit", status: "active" },
  { id: 7, categoryName: "6 Port Din rail Mount Loaded LIU", status: "active" },
  { id: 8, categoryName: "8 Port Din rail Mount Loaded LIU", status: "active" },
  {
    id: 9,
    categoryName: "12 Port Din rail Mount Loaded LIU",
    status: "active",
  },
  {
    id: 10,
    categoryName: "24 Port Din rail Mount Loaded LIU",
    status: "InActive",
  },
  {
    id: 11,
    categoryName: "6/8 Port wall Mount Loaded LIU",
    status: "InActive",
  },
  { id: 12, categoryName: "12 Port wall Mount Loaded LIU", status: "InActive" },
  { id: 13, categoryName: "24 Port wall Mount Loaded LIU", status: "active" },
  { id: 14, categoryName: "48 Port wall Mount Loaded LIU", status: "active" },
  {
    id: 15,
    categoryName: "Mini Rack 6 Port Rack Mount Loaded LIU",
    status: "active",
  },
  {
    id: 16,
    categoryName: "1U 12/24 Port Rack Mount Loaded LIU",
    status: "active",
  },
  {
    id: 17,
    categoryName: "2U 48 Port Rack Mount Loaded LIU",
    status: "active",
  },
  {
    id: 18,
    categoryName: "4U 96 Port Rack Mount Loaded LIU",
    status: "active",
  },
  {
    id: 19,
    categoryName: "1U 12/24 Port Sliding Rack Mount Loaded LIU",
    status: "InActive",
  },
  {
    id: 20,
    categoryName: "2U 48/96 Port Sliding Rack Mount Loaded LIU",
    status: "InActive",
  },
  {
    id: 21,
    categoryName: "4U 96/192 Port Sliding Rack Mount Loaded LIU",
    status: "InActive",
  },
  { id: 22, categoryName: "Single Mode", status: "InActive" },
  { id: 23, categoryName: "Multimode (OM1/OM2)", status: "InActive" },
  { id: 24, categoryName: "Multimode (OM3)", status: "InActive" },
  { id: 25, categoryName: "Multimode (OM4)", status: "active" },
  { id: 26, categoryName: "Simplex and Duplex Patch cords", status: "active" },
];

const subCategoryData = [
  {
    id: 1,
    subCategoryName: "DC Relay Module",
    status: "active",
    subCategoryNameId: "dc-relay-module",
  },
  {
    id: 2,
    subCategoryName: "DC Relay Module",
    status: "InActive",
    subCategoryNameId: "dc-relay-module",
  },
  {
    id: 3,
    subCategoryName: "DC Relay Module",
    status: "InActive",
    subCategoryNameId: "dc-relay-module",
  },
  {
    id: 4,
    subCategoryName: "AC Relay Module",
    status: "InActive",
    subCategoryNameId: "ac-relay-module",
  },
  {
    id: 5,
    subCategoryName: "AC Relay Module",
    status: "InActive",
    subCategoryNameId: "ac-relay-module",
  },
  {
    id: 6,
    subCategoryName: "AC Relay Module",
    status: "active",
    subCategoryNameId: "ac-relay-module",
  },
  {
    id: 7,
    subCategoryName: "DC Output Solid State Relay Module",
    status: "active",
    subCategoryNameId: "dc-output-solid-state-relay-module",
  },
  {
    id: 8,
    subCategoryName: "AC Output Solid State Relay Module",
    status: "active",
    subCategoryNameId: "ac-output-solid-state-relay-module",
  },
  {
    id: 9,
    subCategoryName: "Slim Relay Modules",
    status: "active",
    subCategoryNameId: "slim-relay-modules",
  },
  {
    id: 10,
    subCategoryName: "Passive Interface Module",
    status: "active",
    subCategoryNameId: "passive-interface-module",
  },
  {
    id: 11,
    subCategoryName: "CNC Interface Modules",
    status: "active",
    subCategoryNameId: "cnc-interface-modules",
  },
  {
    id: 12,
    subCategoryName: "Special DI LFD Moduls",
    status: "active",
    subCategoryNameId: "special-di-lfd-moduls",
  },
  {
    id: 13,
    subCategoryName: "Field Interface Module (Non-redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-non-redundant-io",
  },
  {
    id: 14,
    subCategoryName: "Field Interface Module (Non-redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-non-redundant-io",
  },
  {
    id: 15,
    subCategoryName: "Field Interface Module (Non-redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-non-redundant-io",
  },
  {
    id: 16,
    subCategoryName: "Field Interface Module (Non-redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-non-redundant-io",
  },
  {
    id: 17,
    subCategoryName: "Field Interface Module (Redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-redundant-io",
  },
  {
    id: 18,
    subCategoryName: "Field Interface Module (Redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-redundant-io",
  },
  {
    id: 19,
    subCategoryName: "Field Interface Module (Redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-redundant-io",
  },
  {
    id: 20,
    subCategoryName: "Field Interface Module (Redundant I/O)",
    status: "active",
    subCategoryNameId: "field-interface-module-redundant-io",
  },
];

const combinedData = displayData?.map((displayName) => {
  const category = categoryData?.find(
    (category) => category?.id === displayName?.id
  );
  return {
    ...displayName,
    categoryName: category ? category?.categoryName : "Unknown",
  };
});

const combineSubCategorydData = subCategoryData.map((subCategory) => {
  const category = categoryData.find(
    (category) => category.id === subCategory.id
  );
  return {
    ...subCategory,
    categoryName: category ? category.categoryName : "Unknown",
  };
});

const mobile_features = [
  {
    id: 101,
    feature: "Battery Life",
    status: "active",
    specification: "5000mAh",
  },
  {
    id: 102,
    feature: "Screen Size",
    status: "active",
    specification: "6.5 inches",
  },
  {
    id: 103,
    feature: "Camera Quality",
    status: "inactive",
    specification: "108MP",
  },
  {
    id: 104,
    feature: "Processor Speed",
    status: "active",
    specification: "2.8GHz",
  },
  { id: 105, feature: "RAM", status: "active", specification: "8GB" },
  {
    id: 106,
    feature: "Storage Capacity",
    status: "inactive",
    specification: "128GB",
  },
  {
    id: 107,
    feature: "Operating System",
    status: "active",
    specification: "Android 11",
  },
  { id: 108, feature: "Weight", status: "active", specification: "180g" },
  {
    id: 109,
    feature: "Dimensions",
    status: "inactive",
    specification: "160.9 x 74.4 x 8.1 mm",
  },
  {
    id: 110,
    feature: "Connectivity Options",
    status: "active",
    specification: "WiFi, Bluetooth 5.0",
  },
];

const inventoryItems = [
  {
    id: 1,
    orderNo: "ORD001",
    productName: "Smartphone",
    quantity: 6,
    salesReturn: 3,
    status: "active",
    price: 499.99,
    companyName: "TechCorp",
  },
  {
    id: 2,
    orderNo: "ORD002",
    productName: "Laptop",
    quantity: 80,
    salesReturn: 2,
    status: "inactive",
    price: 1099.99,
    companyName: "ComputeWorld",
  },
  {
    id: 3,
    orderNo: "ORD003",
    productName: "Smartwatch",
    quantity: 0,
    salesReturn: 5,
    status: "active",
    price: 299.99,
    companyName: "GadgetCentral",
  },
  {
    id: 4,
    orderNo: "ORD004",
    productName: "Tablet",
    quantity: 77,
    salesReturn: 4,
    status: "active",
    price: 399.99,
    companyName: "TechCorp",
  },
  {
    id: 5,
    orderNo: "ORD005",
    productName: "Headphones",
    quantity: 200,
    salesReturn: 8,
    status: "active",
    price: 129.99,
    companyName: "SoundTech",
  },
  {
    id: 6,
    orderNo: "ORD006",
    productName: "Desktop Computer",
    quantity: 0,
    salesReturn: 1,
    status: "active",
    price: 1499.99,
    companyName: "ComputeWorld",
  },
  {
    id: 7,
    orderNo: "ORD007",
    productName: "Camera",
    quantity: 70,
    salesReturn: 3,
    status: "inactive",
    price: 799.99,
    companyName: "PhotoPro",
  },
  {
    id: 8,
    orderNo: "ORD008",
    productName: "Fitness Tracker",
    quantity: 180,
    salesReturn: 6,
    status: "active",
    price: 79.99,
    companyName: "HealthGear",
  },
  {
    id: 9,
    orderNo: "ORD009",
    productName: "Bluetooth Speaker",
    quantity: 120,
    salesReturn: 4,
    status: "active",
    price: 59.99,
    companyName: "SoundTech",
  },
  {
    id: 10,
    orderNo: "ORD010",
    productName: "Gaming Console",
    quantity: 90,
    salesReturn: 2,
    status: "active",
    price: 399.99,
    companyName: "GameZone",
  },
];

const salesData = [
  {
    id: 1,
    customerName: "John Doe",
    customerType: "Individual",
    orderNo: "ORD001",
    totalAmount: 150.0,
    receivedAmount: 100.0,
    pendingAmount: 50.0,
  },
  {
    id: 2,
    customerName: "Jane Smith",
    customerType: "Corporate",
    orderNo: "ORD002",
    totalAmount: 300.0,
    receivedAmount: 200.0,
    pendingAmount: 100.0,
  },
  {
    id: 3,
    customerName: "Alice Johnson",
    customerType: "Individual",
    orderNo: "ORD003",
    totalAmount: 250.0,
    receivedAmount: 250.0,
    pendingAmount: 0.0,
  },
  {
    id: 4,
    customerName: "Bob Brown",
    customerType: "Corporate",
    orderNo: "ORD004",
    totalAmount: 400.0,
    receivedAmount: 300.0,
    pendingAmount: 100.0,
  },
  {
    id: 5,
    customerName: "Charlie Davis",
    customerType: "Individual",
    orderNo: "ORD005",
    totalAmount: 500.0,
    receivedAmount: 400.0,
    pendingAmount: 100.0,
  },
];

const individualCustomer = [
  {
    id: 1,
    name: "Jenny Simmonds",
    date: "24-11-2023",
    balance: "211.00",
    orders: "10",
    status: "active",
    customer_type: "gst company",
    last_login_date: "01-07-2024",
  },
  {
    id: 2,
    name: "Ammara Molloy",
    date: "01-04-2024",
    balance: "211.00",
    orders: "10",
    status: "active",
    customer_type: "non-gst company",
    last_login_date: "05-07-2024",
  },
  {
    id: 3,
    name: "Anisa Forster",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "active",
    customer_type: "gst company",
    last_login_date: "10-06-2024",
  },
  {
    id: 4,
    name: "Hashir Wilson",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "inactive",
    customer_type: "non-gst company",
    last_login_date: "20-06-2024",
  },
  {
    id: 5,
    name: "Grover Sampson",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "active",
    customer_type: "gst company",
    last_login_date: "25-06-2024",
  },
  {
    id: 6,
    name: "Nelson Mckeown",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "inactive",
    customer_type: "non-gst company",
    last_login_date: "30-06-2024",
  },
  {
    id: 7,
    name: "Zunaira Akhtar",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "active",
    customer_type: "gst company",
    last_login_date: "02-07-2024",
  },
  {
    id: 8,
    name: "Natan Kramer",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "inactive",
    customer_type: "non-gst company",
    last_login_date: "07-07-2024",
  },
  {
    id: 9,
    name: "Jesse Pollard",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "active",
    customer_type: "gst company",
    last_login_date: "12-07-2024",
  },
];

const corporateCustomer = [
  {
    id: 1,
    name: "Anisa Forster",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "ative",
    customer_type: "gst company",
    custoemer_id: 1,
    last_login_date: "10-06-2024",
    register_as: "Sales",
  },
  {
    id: 2,
    name: "Hashir Wilson",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "inActive",
    customer_type: "non-gst company",
    custoemer_id: 2,
    last_login_date: "20-06-2024",
    register_as: "Admin",
  },
  {
    id: 3,
    name: "Grover Sampson",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "inActive",
    customer_type: "gst company",
    custoemer_id: 3,
    last_login_date: "25-06-2024",
    register_as: "Technition",
  },
  {
    id: 4,
    name: "Nelson Mckeown",
    date: "15-06-2022",
    balance: "211.00",
    orders: "10",
    status: "inActive",
    customer_type: "non-gst company",
    custoemer_id: 4,
    last_login_date: "30-06-2024",
    register_as: "Admin",
  },
];

const ContactData = [
  {
    id: 1,
    customerName: "John Doe",
    email: "john.doe@example.com",
    date: "2024-07-10",
    subject: "Inquiry about new product",
    message: "Can you provide more details about the new laptop?",
    action: "View",
  },
  {
    id: 2,
    customerName: "Jane Smith",
    email: "jane.smith@example.com",
    date: "2024-07-11",
    subject: "Order status update",
    message: "When will my order be shipped?",
    action: "Reply",
  },
  {
    id: 3,
    customerName: "Michael Johnson",
    email: "michael.johnson@example.com",
    date: "2024-07-12",
    subject: "Issue with recent purchase",
    message: "The tablet I received is not working.",
    action: "Resolve",
  },
  {
    id: 4,
    customerName: "Emily Brown",
    email: "emily.brown@example.com",
    date: "2024-07-13",
    subject: "Return request",
    message: "I would like to return the headphones.",
    action: "Process",
  },
  {
    id: 5,
    customerName: "Chris Lee",
    email: "chris.lee@example.com",
    date: "2024-07-14",
    subject: "Feedback on smartwatch",
    message: "The smartwatch is amazing! Thank you.",
    action: "Acknowledge",
  },
];

const newProductRequest = [
  {
    id: 1,
    customerName: "John Doe",
    companyName: "Tech Solutions Ltd.",
    productName: "Laptop",
    brandName: "Dell",
    contact: "555-123-4567",
  },
  {
    id: 2,
    customerName: "Jane Smith",
    companyName: "Innovatech Inc.",
    productName: "Smartphone",
    brandName: "Apple",
    contact: "555-234-5678",
  },
  {
    id: 3,
    customerName: "Michael Johnson",
    companyName: "Gadget Works",
    productName: "Tablet",
    brandName: "Samsung",
    contact: "555-345-6789",
  },
  {
    id: 4,
    customerName: "Emily Brown",
    companyName: "ElectroMart",
    productName: "Headphones",
    brandName: "Sony",
    contact: "555-456-7890",
  },
  {
    id: 5,
    customerName: "Chris Lee",
    companyName: "TechHub",
    productName: "Smartwatch",
    brandName: "Garmin",
    contact: "555-567-8901",
  },
];

const productsData = [
  {
    productCode: "P001",
    subSubCategoryName: "Electronics > Accessories > Headphones",
    productName: "Wireless Headphones",
    manufactureName: "AudioTech",
    manufacturePartNumber: "ATH-1234",
    minimumPurchasedQuantity: 5,
    minimumStockQuantityWarning: 10,
    sellingPrice: 99.99,
    shortDescription:
      "High-quality wireless headphones with noise-cancellation.",
    longDescription:
      "Experience the best sound quality with AudioTech wireless headphones. Features active noise-cancellation and a long-lasting battery.",
    specifications:
      "Bluetooth 5.0, 40mm drivers, 20-hour battery life, noise-cancellation",
    images: ["image1.jpg", "image2.jpg", "image3.jpg"],
    technicalDataSheets: ["datasheet1.pdf", "datasheet2.pdf"],
    displayQuantity: 20,
    reservedQuantity: 5,
    salesQuantity: 15,
    status: "active",
  },
  {
    productCode: "P002",
    subSubCategoryName: "Home & Kitchen > Appliances > Blenders",
    productName: "High-Speed Blender",
    manufactureName: "KitchenPro",
    manufacturePartNumber: "KP-5678",
    minimumPurchasedQuantity: 3,
    minimumStockQuantityWarning: 5,
    sellingPrice: 79.99,
    shortDescription: "Versatile high-speed blender for smoothies and more.",
    longDescription:
      "Blend your favorite smoothies, soups, and sauces with the KitchenPro high-speed blender. Powerful motor and easy-to-clean design.",
    specifications: "1000W motor, 1.5L jug, multiple speed settings, BPA-free",
    images: ["blender1.jpg", "blender2.jpg", "blender3.jpg"],
    technicalDataSheets: ["blender_datasheet1.pdf", "blender_datasheet2.pdf"],
    displayQuantity: 30,
    reservedQuantity: 8,
    salesQuantity: 22,
    status: "active",
  },
  {
    productCode: "P003",
    subSubCategoryName: "Sports & Outdoors > Equipment > Tents",
    productName: "4-Person Camping Tent",
    manufactureName: "OutdoorGear",
    manufacturePartNumber: "OG-2345",
    minimumPurchasedQuantity: 2,
    minimumStockQuantityWarning: 4,
    sellingPrice: 129.99,
    shortDescription: "Spacious 4-person tent for camping trips.",
    longDescription:
      "Enjoy the great outdoors with the OutdoorGear 4-person camping tent. Durable and easy to set up, perfect for family trips.",
    specifications: "Waterproof, UV protection, 4-person capacity, easy setup",
    images: ["tent1.jpg", "tent2.jpg", "tent3.jpg"],
    technicalDataSheets: ["tent_datasheet1.pdf", "tent_datasheet2.pdf"],
    displayQuantity: 10,
    reservedQuantity: 2,
    salesQuantity: 8,

    status: "active",
  },
  {
    productCode: "P004",
    subSubCategoryName: "Beauty & Health > Skincare > Moisturizers",
    productName: "Hydrating Face Cream",
    manufactureName: "SkinEssence",
    manufacturePartNumber: "SE-9876",
    minimumPurchasedQuantity: 10,
    minimumStockQuantityWarning: 15,
    sellingPrice: 24.99,
    shortDescription: "Deeply hydrating face cream for all skin types.",
    longDescription:
      "Keep your skin moisturized and glowing with SkinEssence hydrating face cream. Suitable for all skin types, non-greasy formula.",
    specifications:
      "50ml, paraben-free, suitable for all skin types, non-greasy",
    images: ["cream1.jpg", "cream2.jpg", "cream3.jpg"],
    technicalDataSheets: ["cream_datasheet1.pdf", "cream_datasheet2.pdf"],
    displayQuantity: 50,
    reservedQuantity: 10,
    salesQuantity: 40,
    status: "active",
  },
  {
    productCode: "P005",
    subSubCategoryName: "Automotive > Accessories > Car Covers",
    productName: "All-Weather Car Cover",
    manufactureName: "AutoShield",
    manufacturePartNumber: "AS-3456",
    minimumPurchasedQuantity: 1,
    minimumStockQuantityWarning: 3,
    sellingPrice: 59.99,
    shortDescription: "Durable all-weather car cover for maximum protection.",
    longDescription:
      "Protect your car from the elements with AutoShield all-weather car cover. Waterproof and UV-resistant, fits most cars.",
    specifications: "Waterproof, UV-resistant, fits most cars, easy to install",
    images: ["carcover1.jpg", "carcover2.jpg", "carcover3.jpg"],
    technicalDataSheets: ["carcover_datasheet1.pdf", "carcover_datasheet2.pdf"],
    displayQuantity: 5,
    reservedQuantity: 1,
    salesQuantity: 4,

    status: "active",
  },
  {
    productCode: "P006",
    subSubCategoryName: "Fashion > Footwear > Sneakers",
    productName: "Running Sneakers",
    manufactureName: "FitGear",
    manufacturePartNumber: "FG-4567",
    minimumPurchasedQuantity: 6,
    minimumStockQuantityWarning: 8,
    sellingPrice: 49.99,
    shortDescription: "Comfortable and stylish running sneakers.",
    longDescription:
      "Run in style with FitGear running sneakers. Lightweight, breathable, and comfortable for all-day wear.",
    specifications:
      "Lightweight, breathable, cushioned sole, various sizes available",
    images: ["sneakers1.jpg", "sneakers2.jpg", "sneakers3.jpg"],
    technicalDataSheets: ["sneakers_datasheet1.pdf", "sneakers_datasheet2.pdf"],
    displayQuantity: 40,
    reservedQuantity: 10,
    salesQuantity: 30,
    status: "active",
  },
  {
    productCode: "P007",
    subSubCategoryName: "Toys & Games > Educational Toys > STEM Kits",
    productName: "Robotics STEM Kit",
    manufactureName: "EduTech",
    manufacturePartNumber: "ET-7890",
    minimumPurchasedQuantity: 4,
    minimumStockQuantityWarning: 6,
    sellingPrice: 89.99,
    shortDescription: "Build and program your own robot with this STEM kit.",
    longDescription:
      "Learn the basics of robotics and programming with EduTech robotics STEM kit. Perfect for young engineers.",
    specifications:
      "Includes all parts, compatible with various programming languages, educational",
    images: ["stemkit1.jpg", "stemkit2.jpg", "stemkit3.jpg"],
    technicalDataSheets: ["stemkit_datasheet1.pdf", "stemkit_datasheet2.pdf"],
    displayQuantity: 20,
    reservedQuantity: 5,
    salesQuantity: 15,
    status: "active",
  },
  {
    productCode: "P008",
    subSubCategoryName: "Tools & Home Improvement > Hand Tools > Screwdrivers",
    productName: "Precision Screwdriver Set",
    manufactureName: "ToolMaster",
    manufacturePartNumber: "TM-0123",
    minimumPurchasedQuantity: 8,
    minimumStockQuantityWarning: 12,
    sellingPrice: 19.99,
    shortDescription: "High-quality precision screwdriver set.",
    longDescription:
      "Handle any small repair job with ToolMaster precision screwdriver set. Includes various sizes for different tasks.",
    specifications: "10-piece set, magnetic tips, ergonomic handle, durable",
    images: ["screwdrivers1.jpg", "screwdrivers2.jpg", "screwdrivers3.jpg"],
    technicalDataSheets: [
      "screwdrivers_datasheet1.pdf",
      "screwdrivers_datasheet2.pdf",
    ],
    displayQuantity: 60,
    reservedQuantity: 15,
    salesQuantity: 45,
    status: "active",
  },
  {
    productCode: "P009",
    subSubCategoryName: "Office Supplies > Stationery > Notebooks",
    productName: "Hardcover Notebook",
    manufactureName: "WriteWell",
    manufacturePartNumber: "WW-2345",
    minimumPurchasedQuantity: 20,
    minimumStockQuantityWarning: 30,
    sellingPrice: 12.99,
    shortDescription: "Durable hardcover notebook for all your writing needs.",
    longDescription:
      "Jot down your thoughts and notes in the WriteWell hardcover notebook. Sturdy and stylish design, perfect for everyday use.",
    specifications: "200 pages, A5 size, hardcover, lined pages",
    images: ["notebook1.jpg", "notebook2.jpg", "notebook3.jpg"],
    technicalDataSheets: ["notebook_datasheet1.pdf", "notebook_datasheet2.pdf"],
    displayQuantity: 100,
    reservedQuantity: 25,
    salesQuantity: 75,
    status: "active",
  },
  {
    productCode: "P010",
    subSubCategoryName: "Pet Supplies > Cats > Toys",
    productName: "Interactive Cat Toy",
    manufactureName: "PetFun",
    manufacturePartNumber: "PF-5678",
    minimumPurchasedQuantity: 10,
    minimumStockQuantityWarning: 15,
    sellingPrice: 14.99,
    shortDescription: "Keep your cat entertained with this interactive toy.",
    longDescription:
      "Your cat will love playing with the PetFun interactive cat toy. Engages their natural hunting instincts and keeps them active.",
    specifications: "Battery-operated, various modes, durable, safe materials",
    images: ["cattoy1.jpg", "cattoy2.jpg", "cattoy3.jpg"],
    technicalDataSheets: ["cattoy_datasheet1.pdf", "cattoy_datasheet2.pdf"],
    displayQuantity: 50,
    reservedQuantity: 10,
    salesQuantity: 40,
    status: "active",
  },
];

const invoiceData = [
  {
    id: "1",
    invoiceId: "500884010",
    title: "Marshall Kilburn Portable Wireless Speaker",
    dateCreate: "20-1-2020",
    amount: "42.99",
    status: "Successful delivery",
  },
  {
    id: "2",
    invoiceId: "593347935",
    title: "Herschel Leather Duffle Bag In Brown Color",
    dateCreate: "20-1-2020",
    amount: "199.99",
    status: "Cancel",
  },
];

const BlogData = [
  {
    id: 1,
    title: "Smartphone X200",
    image:
      "https://wpmartfury.com/marketplace5/wp-content/uploads/sites/6/2017/12/blogv10-790x510.jpg",
    slug: "smartphone-x200",
    status: "active",
    category: "Technology",
    published_date: "25-11-2025",
  },
  {
    id: 2,
    title: "4K Ultra HD TV",
    image:
      "https://wpmartfury.com/marketplace5/wp-content/uploads/sites/6/2017/12/blogv10-790x510.jpg",
    slug: "4k-ultra-hd-tv",
    status: "InActive",
    category: "Entertainment",
    published_date: "25-11-2025",
  },
  {
    id: 3,
    title: "Wireless Noise Cancelling Headphones",
    image:
      "https://wpmartfury.com/marketplace5/wp-content/uploads/sites/6/2017/12/blogv10-790x510.jpg",
    slug: "wireless-noise-cancelling-headphones",
    status: "InActive",
    category: "Fashion",
    published_date: "25-11-2025",
  },
  {
    id: 4,
    title: "Gaming Laptop Pro",
    image:
      "https://wpmartfury.com/marketplace5/wp-content/uploads/sites/6/2017/12/blogv10-790x510.jpg",
    slug: "gaming-laptop-pro",
    status: "Active",
    category: "Business",
    published_date: "25-11-2025",
  },
  {
    id: 5,
    title: "Smart Home Speaker",
    image:
      "https://wpmartfury.com/marketplace5/wp-content/uploads/sites/6/2017/12/blogv10-790x510.jpg",
    slug: "smart-home-speaker",
    status: "Active",
    category: "Others",
    published_date: "25-11-2025",
  },
];

const mainBannerData = [
  {
    mainBannerid: 1,
    mainBannerLink: "https://next-app.harmistechnology.com",
    mainBannerimage:
      "https://skote-v-light.react.themesbrand.com/static/media/img-4.d54eed02ef5a376c9b2b.jpg",
    status: "active",
  },
  {
    mainBannerid: 2,
    mainBannerLink: "https://next-app.harmistechnology.com",
    mainBannerimage:
      "https://skote-v-light.react.themesbrand.com/static/media/img-4.d54eed02ef5a376c9b2b.jpg",
    status: "active",
  },
];

const detailPageBannerData = [
  {
    id: 1,
    Link: "https://next-app.harmistechnology.com",
    image:
      "https://skote-v-light.react.themesbrand.com/static/media/img-4.d54eed02ef5a376c9b2b.jpg",
    status: "active",
  },
];

const subAdminData = [
  {
    id: 1,
    fullName: "Patel Varun M",
    displayName: "VarunPatel",
    email: "varunpatel@gmail.com",
    role: "Admin",
    phone: 8525658545,
    password: 123456,
    status: "active",
  },
  {
    id: 2,
    fullName: "Kanani Sagar",
    displayName: "SagarKanani",
    email: "kananisagar@gmail.com",
    role: "Admin",
    phone: 8200657865,
    password: 123456,
    status: "active",
  },
  {
    id: 3,
    fullName: "Johan Kelvin",
    displayName: "JohnaKelvin",
    email: "johankelvin@gmail.com",
    role: "Sales",
    phone: 9874652154,
    password: 123456,
    status: "Inactive",
  },
  {
    id: 4,
    fullName: "Ramesh Vasava",
    displayName: "RameshVasava",
    email: "vasavaramesh@gmail.com",
    role: "Admin",
    phone: 8525658545,
    password: 123456,
    status: "Inactive",
  },
  {
    id: 5,
    fullName: "Dharmesh Makawana",
    displayName: "DharmeshMakawana",
    email: "dharmeshmakwana@gmail.com",
    role: "Technition",
    phone: 7465892585,
    password: 123456,
    status: "Inactive",
  },
];

const bomData = [
  {
    id: 1,
    customerName: "Acme Corp",
    requestOfAdd: "Add 50 units of widget A",
    customerRequestDate: "2024-07-25",
    excelFile: "acme_widget_A.xlsx",
    yourExcel: "admin_upload_A1.xlsx",
    uploadedExcelDate: "2024-08-01",
  },
  {
    id: 2,
    customerName: "Globex Inc.",
    requestOfAdd: "Add 100 units of widget B",
    customerRequestDate: "2024-07-26",
    excelFile: "globex_widget_B.xlsx",
    yourExcel: "admin_upload_B1.xlsx",
    uploadedExcelDate: "2024-08-02",
  },
  {
    id: 3,
    customerName: "Soylent Corp",
    requestOfAdd: "Add 200 units of widget C",
    customerRequestDate: "2024-07-27",
    excelFile: "soylent_widget_C.xlsx",
    yourExcel: "admin_upload_C1.xlsx",
    uploadedExcelDate: "2024-08-03",
  },
  {
    id: 4,
    customerName: "Initech",
    requestOfAdd: "Add 75 units of widget D",
    customerRequestDate: "2024-07-28",
    excelFile: "initech_widget_D.xlsx",
    yourExcel: "admin_upload_D1.xlsx",
    uploadedExcelDate: "2024-08-04",
  },
  {
    id: 5,
    customerName: "Umbrella Corp",
    requestOfAdd: "Add 30 units of widget E",
    customerRequestDate: "2024-07-29",
    excelFile: "umbrella_widget_E.xlsx",
    yourExcel: "admin_upload_E1.xlsx",
    uploadedExcelDate: "2024-08-05",
  },
  {
    id: 6,
    customerName: "Hooli",
    requestOfAdd: "Add 60 units of widget F",
    customerRequestDate: "2024-07-30",
    excelFile: "hooli_widget_F.xlsx",
    yourExcel: "admin_upload_F1.xlsx",
    uploadedExcelDate: "2024-08-06",
  },
  {
    id: 7,
    customerName: "Vehement Capital Partners",
    requestOfAdd: "Add 40 units of widget G",
    customerRequestDate: "2024-07-31",
    excelFile: "vehement_widget_G.xlsx",
    yourExcel: "admin_upload_G1.xlsx",
    uploadedExcelDate: "2024-08-07",
  },
  {
    id: 8,
    customerName: "Massive Dynamic",
    requestOfAdd: "Add 120 units of widget H",
    customerRequestDate: "2024-08-01",
    excelFile: "massive_widget_H.xlsx",
    yourExcel: "admin_upload_H1.xlsx",
    uploadedExcelDate: "2024-08-08",
  },
  {
    id: 9,
    customerName: "Cyberdyne Systems",
    requestOfAdd: "Add 90 units of widget I",
    customerRequestDate: "2024-08-02",
    excelFile: "cyberdyne_widget_I.xlsx",
    yourExcel: "admin_upload_I1.xlsx",
    uploadedExcelDate: "2024-08-09",
  },
  {
    id: 10,
    customerName: "Stark Industries",
    requestOfAdd: "Add 150 units of widget J",
    customerRequestDate: "2024-08-03",
    excelFile: "stark_widget_J.xlsx",
    yourExcel: "admin_upload_J1.xlsx",
    uploadedExcelDate: "2024-08-10",
  },
];

const quoteRequestData = [
  {
    id: 1,
    quoteNumber: "Q-1001",
    customerName: "John Doe",
    customerContactNumber: "9733789598",
    date: "2024-08-01",
  },
  {
    id: 2,
    quoteNumber: "Q-1002",
    customerName: "Jane Smith",
    customerContactNumber: "9876543210",
    date: "2024-08-03",
  },
  {
    id: 3,
    quoteNumber: "Q-1003",
    customerName: "Alice Johnson",
    customerContactNumber: "9123456789",
    date: "2024-08-05",
  },
  {
    id: 4,
    quoteNumber: "Q-1004",
    customerName: "Bob Brown",
    customerContactNumber: "9786543210",
    date: "2024-08-07",
  },
  {
    id: 5,
    quoteNumber: "Q-1005",
    customerName: "Charlie Green",
    customerContactNumber: "9654321098",
    date: "2024-08-10",
  },
];

const individualCartData = [
  {
    cart_Id: 1,
    Cart_Number: "CART1001",
    created_date: "2024-08-01T10:00:00Z",
    updated_date: "2024-08-01T12:00:00Z",
    status: "order confirmed",
    customer_id: 101,
    customer_name: "John Doe",
    products: [
      { product_id: 201, product_name: "Laptop", quantity: 1, price: 1200.0 },
      { product_id: 202, product_name: "Mouse", quantity: 2, price: 25.0 },
    ],
  },
  {
    cart_Id: 2,
    Cart_Number: "CART1002",
    created_date: "2024-08-02T11:00:00Z",
    updated_date: "2024-08-02T13:00:00Z",
    status: "pending",
    customer_id: 102,
    customer_name: "Jane Smith",
    products: [
      { product_id: 203, product_name: "Keyboard", quantity: 1, price: 50.0 },
      { product_id: 204, product_name: "Monitor", quantity: 1, price: 300.0 },
    ],
  },
  {
    cart_Id: 3,
    Cart_Number: "CART1003",
    created_date: "2024-08-03T09:30:00Z",
    updated_date: "2024-08-03T10:30:00Z",
    status: "expired",
    customer_id: 103,
    customer_name: "Alice Johnson",
    products: [
      { product_id: 205, product_name: "Printer", quantity: 1, price: 150.0 },
    ],
  },
  {
    cart_Id: 4,
    Cart_Number: "CART1004",
    created_date: "2024-08-04T14:00:00Z",
    updated_date: "2024-08-04T15:00:00Z",
    status: "order confirmed",
    customer_id: 104,
    customer_name: "Bob Brown",
    products: [
      {
        product_id: 206,
        product_name: "Smartphone",
        quantity: 1,
        price: 800.0,
      },
      {
        product_id: 207,
        product_name: "Headphones",
        quantity: 1,
        price: 120.0,
      },
    ],
  },
  {
    cart_Id: 5,
    Cart_Number: "CART1005",
    created_date: "2024-08-05T08:00:00Z",
    updated_date: "2024-08-05T09:00:00Z",
    status: "pending",
    customer_id: 105,
    customer_name: "Carol White",
    products: [
      { product_id: 208, product_name: "Webcam", quantity: 1, price: 70.0 },
    ],
  },
  {
    cart_Id: 6,
    Cart_Number: "CART1006",
    created_date: "2024-08-06T16:00:00Z",
    updated_date: "2024-08-06T17:00:00Z",
    status: "expired",
    customer_id: 106,
    customer_name: "David Green",
    products: [
      { product_id: 209, product_name: "Tablet", quantity: 1, price: 200.0 },
      {
        product_id: 210,
        product_name: "Smartwatch",
        quantity: 1,
        price: 250.0,
      },
    ],
  },
  {
    cart_Id: 7,
    Cart_Number: "CART1007",
    created_date: "2024-08-07T12:00:00Z",
    updated_date: "2024-08-07T13:00:00Z",
    status: "order confirmed",
    customer_id: 107,
    customer_name: "Eve Black",
    products: [
      { product_id: 211, product_name: "Camera", quantity: 1, price: 500.0 },
    ],
  },
  {
    cart_Id: 8,
    Cart_Number: "CART1008",
    created_date: "2024-08-08T07:30:00Z",
    updated_date: "2024-08-08T08:30:00Z",
    status: "pending",
    customer_id: 108,
    customer_name: "Frank Adams",
    products: [
      {
        product_id: 212,
        product_name: "External Hard Drive",
        quantity: 1,
        price: 90.0,
      },
    ],
  },
  {
    cart_Id: 9,
    Cart_Number: "CART1009",
    created_date: "2024-08-09T18:00:00Z",
    updated_date: "2024-08-09T19:00:00Z",
    status: "expired",
    customer_id: 109,
    customer_name: "Grace Lee",
    products: [
      {
        product_id: 213,
        product_name: "Bluetooth Speaker",
        quantity: 2,
        price: 60.0,
      },
    ],
  },
  {
    cart_Id: 10,
    Cart_Number: "CART1010",
    created_date: "2024-08-10T15:00:00Z",
    updated_date: "2024-08-10T16:00:00Z",
    status: "order confirmed",
    customer_id: 110,
    customer_name: "Hank Wilson",
    products: [
      {
        product_id: 214,
        product_name: "Gaming Chair",
        quantity: 1,
        price: 220.0,
      },
      { product_id: 215, product_name: "Desk Lamp", quantity: 1, price: 45.0 },
    ],
  },
];

const cartProducts = [
  {
    id: 1,
    product_img: "https://example.com/images/laptop.jpg",
    product_name: "Laptop",
    product_code: "LP1234",
    price: 1200.0,
    quantity: 1,
    total: 1200.0,
    discount_percentage: 10, // 10% discount
    discount_value: 120.0, // $120 discount value
  },
  {
    id: 2,
    product_img: "https://example.com/images/mouse.jpg",
    product_name: "Mouse",
    product_code: "MS5678",
    price: 25.0,
    quantity: 2,
    total: 50.0,
    discount_percentage: 5, // 5% discount
    discount_value: 2.5, // $2.5 discount value
  },
  {
    id: 3,
    product_img: "https://example.com/images/keyboard.jpg",
    product_name: "Keyboard",
    product_code: "KB9101",
    price: 50.0,
    quantity: 1,
    total: 50.0,
    discount_percentage: 0, // No discount
    discount_value: 0.0, // No discount value
  },
  {
    id: 4,
    product_img: "https://example.com/images/monitor.jpg",
    product_name: "Monitor",
    product_code: "MN1121",
    price: 300.0,
    quantity: 1,
    total: 300.0,
    discount_percentage: 15, // 15% discount
    discount_value: 45.0, // $45 discount value
  },
  {
    id: 5,
    product_img: "https://example.com/images/printer.jpg",
    product_name: "Printer",
    product_code: "PR3141",
    price: 150.0,
    quantity: 1,
    total: 150.0,
    discount_percentage: 8, // 8% discount
    discount_value: 12.0, // $12 discount value
  },
];

const customerOrderData = [
  {
    name: "John Doe",
    orderId: "ORD001",
    date: "2024-08-01",
    price: 120.5,
    paymentStatus: "Paid",
    paymentMethod: "Credit Card",
    address: "123 Elm Street, Springfield, IL",
  },
  {
    name: "Jane Smith",
    orderId: "ORD002",
    date: "2024-08-02",
    price: 89.99,
    paymentStatus: "Pending",
    paymentMethod: "PayPal",
    address: "456 Maple Avenue, Rivertown, TX",
  },
  {
    name: "Alice Johnson",
    orderId: "ORD003",
    date: "2024-08-03",
    price: 54.75,
    paymentStatus: "Paid",
    paymentMethod: "Debit Card",
    address: "789 Oak Lane, Metropolis, NY",
  },
  {
    name: "Bob Brown",
    orderId: "ORD004",
    date: "2024-08-04",
    price: 99.99,
    paymentStatus: "Refunded",
    paymentMethod: "Bank Transfer",
    address: "321 Pine Road, Lakewood, CA",
  },
  {
    name: "Charlie Davis",
    orderId: "ORD005",
    date: "2024-08-05",
    price: 150.0,
    paymentStatus: "Paid",
    paymentMethod: "Credit Card",
    address: "654 Birch Street, Hilltown, FL",
  },
  {
    name: "Diana Evans",
    orderId: "ORD006",
    date: "2024-08-06",
    price: 77.45,
    paymentStatus: "Pending",
    paymentMethod: "PayPal",
    address: "987 Cedar Blvd, Springdale, CO",
  },
  {
    name: "Ethan Green",
    orderId: "ORD007",
    date: "2024-08-07",
    price: 135.6,
    paymentStatus: "Paid",
    paymentMethod: "Credit Card",
    address: "432 Maple Drive, Brookside, MA",
  },
  {
    name: "Fiona Harris",
    orderId: "ORD008",
    date: "2024-08-08",
    price: 85.2,
    paymentStatus: "Pending",
    paymentMethod: "Debit Card",
    address: "159 Elm Avenue, Port City, WA",
  },
  {
    name: "George Ives",
    orderId: "ORD009",
    date: "2024-08-09",
    price: 65.3,
    paymentStatus: "Paid",
    paymentMethod: "Bank Transfer",
    address: "258 Oak Street, Coastal Town, OR",
  },
  {
    name: "Hannah Jones",
    orderId: "ORD010",
    date: "2024-08-10",
    price: 110.25,
    paymentStatus: "Paid",
    paymentMethod: "PayPal",
    address: "789 Pine Lane, Central City, NV",
  },
];

export {
  displayData,
  certificateData,
  brandData,
  categoryData,
  combinedData,
  subCategoryData,
  combineSubCategorydData,
  mobile_features,
  inventoryItems,
  salesData,
  individualCustomer,
  corporateCustomer,
  ContactData,
  newProductRequest,
  productsData,
  invoiceData,
  BlogData,
  mainBannerData,
  detailPageBannerData,
  subAdminData,
  bomData,
  quoteRequestData,
  individualCartData,
  cartProducts,
  customerOrderData,
};
